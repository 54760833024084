<template>
  <div class="works3d">
    <div class="works3d_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.worksImg" alt="" />
    </div>
    <div class="works3d_nav" v-if="Object.keys(basicData).length != 0">
      <div class="works3d_nav_type">
        <ul class="nav_col">
          <li
            v-for="(item, idx) in worksType"
            :key="idx"
            :class="[zp_xz == idx ? 'xz_li' : '']"
            @click="getList(item.id, idx, 1)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="nav_sort">
          <span style="color: #999;">排序方式</span>
          <i
            class="el-icon-view"
            :style="{ color: active == 0 ? '#1342ff' : '' }"
            @click="active = 0"
          ></i>
          <i
            class="el-icon-time"
            :style="{ color: active == 1 ? '#1342ff' : '' }"
            @click="active = 1"
          ></i>
        </div>
      </div>
      <ul class="works3d_nav_list">
        <li v-for="(item, idx) in worksList" :key="idx">
          <div class="li_img zz_all_img">
            <img :src="item.site_images" alt="" />
          </div>
          <div class="li_text">
            <h2>{{ item.title }}</h2>
            <p>{{ item.subtitle }}</p>
          </div>
        </li>
      </ul>
      <div class="works3d_nav_btn centerText" @click="loadData()">
        <span
          :style="{
            background: `url(${basicData.worksTit[0].value}) 0 0 / cover`
          }"
        ></span>
        {{ basicData.worksTit[0].key }}
      </div>
    </div>
  </div>
</template>
<script>
import { getPageMsg, getAllList, getProductTypes } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      basicData: {},
      worksType: [],
      zp_xz: 0,
      active: 0,
      worksList: [],
      total: 0,
      form: {
        page: 1,
        pagesize: 20,
        type_id: '',
        project: ''
      }
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(this.changeData() + 'id')
      that.form.project = sessionStorage.getItem(this.changeData() + 'project')
      getProductTypes(that.form.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          that.worksType = res.data.data
        }
      })
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.basicData = res.data.data.basic
        }
      })
      that.getList('', 0)
    },
    getList (i, x, d) {
      if (d) {
        this.form.page = d
      }
      this.zp_xz = x
      this.form.type_id = i
      getAllList(this.form).then(res => {
        if (res.data.code == 1) {
          this.worksList = res.data.data.list
          this.total = res.data.data.total
        }
      })
    },
    loadData () {
      if (this.total > this.form.pagesize * this.form.page) {
        this.form.page += 1
        getAllList(this.form).then(res => {
          if (res.data.code == 1) {
            this.worksList = this.worksList.concat(res.data.data.list)
          }
        })
      } else {
        this.$message.warning('已加载所有的模型。')
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.works3d {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .works3d_bg {
    width: 100%;
    height: auto;
    img{
      width: 100%;
    }
  }
  .works3d_nav {
    padding: 80px 0;
    width: 80%;
    margin: 0 auto;
    .works3d_nav_type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      font-size: 14px;
      .nav_col {
        flex: 1;
        overflow: auto;
        display: flex;
        li {
          min-width: 80px;
          padding: 14px 0;
          margin-left: 15px;
          text-align: center;
          border-radius: 20px;
          color: #1342ff;
          transition: all 0.3s ease-out;
          cursor: pointer;
        }
        li:nth-child(1) {
          margin-left: 0;
        }
        .xz_li {
          color: #fff;
          background-color: #1342ff;
        }
      }
      .nav_sort {
        display: flex;
        align-items: center;
        i {
          margin-left: 15px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    .works3d_nav_list {
      margin-left: -10px;
      margin-right: -10px;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 0 10px;
        margin-bottom: 20px;
        transition: all 0.2s ease-in;
        width: calc(20% - 20px);
        .li_img {
          width: 100%;
          height: calc(100% - 55px);
          background-color: #eeeff4;
          border-radius: 15px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .li_text {
          height: 40px;
          padding: 0 4%;
          margin-top: 15px;
          h2 {
            font-size: 14px;
            color: #444;
            font-weight: 600;
          }
          p {
            font-size: 12px;
            color: #999;
            margin-top: 10px;
          }
        }
      }
    }
    .works3d_nav_btn {
      width: 110px;
      height: 36px;
      border-radius: 80px;
      opacity: 1;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #c7d2ff;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 12px;
      cursor: pointer;
      color: #1342ff;
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: -10px;
      }
    }
  }
}
</style>
